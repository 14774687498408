import React from 'react';
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { BsArrowRight } from "react-icons/bs"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri"

import Layout from "../components/layout"
import SEO from '../components/seo';

const TopBanner = styled.div`
    margin-top: 68px;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #0a3b7c;
    display: flex;
    justify-content: center;
    align-items: center !important;
`

const BlogTitle = styled.h1`
    color: #FFFFFF;
    text-align: center;
    font-size: 38px;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif !important;
    background-color: #00AEEF;
    padding: 10px;
    display: inline;
    @media (max-width: 450px) {
    font-size: 9vw;
    }
`

const LowerSection = styled.div`
    background-color: #FFFFFF;
    padding-top: 50px;
    padding-bottom: 50px;
`

const BlogIndex = styled.div`
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr !important;
    grid-template-rows: auto;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20vh;
    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr !important;
    }
    @media (max-width: 450px) {
        grid-template-columns: 1fr !important;
        padding-bottom: 10vh;
        padding-left: 20px;
        padding-right: 20px;
    }
`

const BlogContainer = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    background-color: #00aeef;
    font-family: 'Montserrat', sans-serif !important;

    
    border-radius: 2px !important;
    overflow: hidden;

`

const BlogImage = styled(Img)`
    width: 100%;
    

`

const BlogBlurb = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    background-color: #00aeef;
    font-family: 'Montserrat', sans-serif !important;
    padding: 20px;
    flex: 1;

    &:hover {
        background-color: #0a3b7c !important;
        transition: all 300ms linear 0s ;
    }

    .read-more {
        color: #FFFFFF;
        font-size: 38px;
        
    }
`

const PostTitle = styled.div`
    color: #FFFFFF;

    .blog-date {
        font-weight: 700;
        font-size: 14px;
    }
    
    @media (max-width: 450px) {
        .blog-date {
            font-size: 3.5vw;
        }

        .blog-title {
            font-size: 5.5vw;
        }
    }
`

const Pagination = styled.div`
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 2fr 1fr !important;
    grid-template-rows: auto;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width: 450px) {
        display: flex !important;
        justify-content: space-between !important;
        padding-left: 20px;
        padding-right: 20px;
    }

`

const PageButton = styled.div`
    display: flex;
    
    .arrow{
        font-size: 10px;
    }

    .page-button{
        text-decoration: none;
        display: block;
        padding: 10px;
        background-color: #00aeef;
        color: #FFFFFF;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 500;
    }

    .page-button:hover {
        background-color: #0a3b7c;
        transition: all 300ms linear 0s ;
    }

`

const PageNumberButtons = styled.div`
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;

    .page-number{
        text-decoration: none;
        display: block;
        padding: 10px;
        background-color: #00aeef;
        color: #FFFFFF;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 500;
    }

    .page-number:hover {
        background-color: #0a3b7c;
        transition: all 300ms linear 0s ;
    }

    @media (max-width: 450px) {
        display: none
    }

`


const Blog = ( { data, pageContext } ) => {

    const { currentPage, numPages } = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages 
    const prevPage = currentPage - 1 === 1 ? "/blog" : `/blog/${(currentPage - 1).toString()}`
    const nextPage = `/blog/${(currentPage + 1).toString()}`

    return (  
        <Layout>
            <SEO 
            pageType="website"
            linkTitle={data.blog.blogPageLinkTitle}
            pageDescription={data.blog.blogPageLinkDescription.blogPageLinkDescription}
            pageURL={`${data.site.siteMetadata.url}blog`}
            pageTitle={data.blog.blogPageTitle}
            linkImage={`https:${data.blog.blogPageImage.file.url}`}
            />
            <TopBanner>
                <BlogTitle>{data.blog.blogPageHeadline}</BlogTitle>
            </TopBanner>
            <LowerSection>
                <BlogIndex >
                    {data.allContentfulBlog.edges.map((edge)=>{
                        return (
                            
                            <BlogContainer key={edge.node.contentful_id} to={`/${edge.node.slug}`} style={{borderRadius: "15px"}}>
                                <BlogImage fluid={edge.node.featuredImage.fluid} />
                                <BlogBlurb >
                                    <PostTitle>
                                        <p style={{ fontFamily: "'Montserrat', sans-serif", scrollPaddingBottom: "20px" }} className="blog-date">{edge.node.date}</p>
                                        <div style={{ minHeight: "100px" }}>
                                            <h1 style={{ fontFamily: "'Montserrat', sans-serif" }} className="blog-title">{edge.node.title}</h1>
                                        </div>

                                    </PostTitle>
                                    <BsArrowRight className="read-more" />
                                </BlogBlurb>

                            </BlogContainer>
                            
                        )
                    })}

                </BlogIndex>
                <Pagination>
                    <PageButton>
                        {!isFirst && (
                            <Link className="page-button" to={prevPage} rel="prev">
                            <RiArrowLeftSLine className="arrow" /> Previous Page
                            </Link>
                        )}
                    </PageButton>
                    <PageNumberButtons>
                        {!isFirst && (Array.from({ length: numPages }, (_, i) => (
                            <Link className="page-number" key={`pagination-number${i + 1}`} to={`/blog${i === 0 ? "" : "/"+(i + 1)}`} activeStyle={{ backgroundColor: "#0a3b7c", cursor: "default" }}>
                            {i + 1}
                            </Link>
                        )))}
                    </PageNumberButtons>
                    <PageButton style={{ justifyContent: "flex-end" }}>
                        {!isLast && (
                            <Link className="page-button" to={nextPage} rel="next" >
                            Next Page <RiArrowRightSLine className="arrow"/>
                            </Link>
                        )}
                    </PageButton>

                </Pagination>

            </LowerSection>
            
        </Layout>
    );
}
 
export default Blog;

export const pageQuery = graphql`
    query ($skip: Int!, $limit: Int!) {
    allContentfulBlog(sort: {fields: date, order: DESC}
        limit: $limit
        skip: $skip
        ) {
        edges {
            node {
                slug
                title
                date(formatString: "MMMM DD, YYYY")
                featuredImage {
                    fluid(maxWidth: 2000) {
                        ...GatsbyContentfulFluid
                    }
                }
                contentful_id
            }
        }
    }
    blog: contentfulSiteSettings(title: {eq: "Site Settings"}) {
        blogPageLinkTitle
        blogPageLinkDescription {
          blogPageLinkDescription
        }
        blogPageTitle
        blogPageImage {
          file {
            url
          }
        }
        blogPageHeadline
    }
    site {
        siteMetadata {
            url
        }
    }
}
`;


